<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      @after-close="afterClose"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <br />
        <div id="BaseView">
          <section data-class="work-list-table">
            <!-- テーブル -->
            <BaseTable
              select-mode="single"
              :fields="fieldsReceive"
              :items="tableItems"
            />
          </section>
        </div>
        <br />

        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="formData"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
          <br />
        </div>

        <div id="BaseView">
          <section data-class="work-list-table">
            <!-- 追加ボタン -->
            <BaseButton
              class="editRowBtn"
              variant="primary"
              icon="plus-circle"
              :columnInfo="resultControlInfo.row_add"
              @click="goAddRow(obj)"
            />
            <!-- 削除ボタン -->
            <BaseButton
              class="editRowBtn"
              variant="primary"
              icon="minus-circle"
              :columnInfo="resultControlInfo.row_delete"
              :disabled="rowDeleteDisabledCheck()"
              @click="goDeleteRow(obj)"
            />
            <!-- 再設定ボタン -->
            <BaseButton
              class="editRowBtn"
              variant="primary"
              icon="edit"
              :columnInfo="resultControlInfo.row_reset"
              @click="goResetCondition(obj)"
            />
            <!-- 解除ボタン -->
            <BaseButton
              class="editRowBtn"
              variant="primary"
              icon="times-circle"
              :columnInfo="resultControlInfo.row_cancel"
              :disabled="rowCancelDisabledCheck()"
              @click="goCancelRow(obj)"
            />
            <!-- テーブル -->
            <BaseTable
              select-mode="single"
              :fields="fields"
              :items="divTableItems"
            />
          </section>
        </div>
      </template>

      <template v-slot:footer="{ cancel }">
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>

    <!-- 登録モーダル -->
    <ConfirmModal
      id="sectionSplit-register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :event="event"
      @addRow="addRow(obj)"
      @deleteRow="deleteRow(obj)"
      @resetRow="resetRow(obj, selectedPtnItem)"
      @cancelRow="cancelRow(obj)"
    />

    <!-- 区間分割パターンマスタモーダル -->
    <SectionDivisionModal
      id="section-division-modal-three"
      type="entry"
      selectMode="single"
      displayModals="three"
      :useUnset="false"
      :selectedSearchCondition="sectionDivisionModalSearchCondition"
      @after-close-set="afterCloseSetSectionDivision"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- （区間分割）場所モーダル -->
    <PlaceModal
      id="place-modal-edit"
      displayModals="two"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>
<script>
import packUpInfo from './OrderPackUpInfo'
import { openSubModal } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  goAddRow,
  addRow,
  goDeleteRow,
  deleteRow,
  goResetRow,
  resetRow,
  clearDivisionVia,
  goResetCondition,
  goCancelRow, //解除
  cancelRow,
} from './OrderSectionSplitModal.js'
import divisionViaInfo from './DivisionViaInfo.js'

const screenId = SCREEN_ID.ORDER_SECTION_SPLIT_MODAL

export default {
  components: {
    SectionDivisionModal: () =>
      import('@/master/sectionDivision/SectionDivisionModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },

  data() {
    return {
      tableItems: [], //CurrentList
      divTableItems: [],
      copyData: [],
      selectedItem: [],
      packUpInfoList: new packUpInfo(), //SearchList
      obj: this,
      registerMessage: null,
      // ConfirmModal用イベント
      event: null,
      rowDeleteDisabled: false,
      oriData: new packUpInfo(),

      // 経由地情報
      formData: new divisionViaInfo(),
      // 再設定情報（パターン）
      selectedPtnItem: [],

      // 再設定の検索条件設定
      sectionDivisionModalSearchCondition: {},

      // 親から設定値
      planningOrderId: -1,
      entryKind: -1,
    }
  },

  props: {
    selectedOrderSectionSplitIds: {
      // type: Array,
      // default: () => [],
      type: Object,
      default: () => {},
    },
  },

  watch: {
    selectedOrderSectionSplitIds() {
      this.planningOrderId = this.selectedOrderSectionSplitIds.planningOrderId
      this.entryKind = this.selectedOrderSectionSplitIds.entryKind
      init(this)
    },
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 受注オーダー明細(ID)
              type: 'text',
              id: 'orderDetailId',
              columnInfo: this.detailInfo.order_detail_id,
            },
            {
              // 受注オーダーのブロックCode
              type: 'text',
              id: 'orderBlockCode',
              columnInfo: this.detailInfo.order_block_code,
            },
            //---------------
            {
              // 出発場所(ID)
              type: 'text',
              id: 'departurePlaceId',
              columnInfo: this.detailInfo.departure_place_id,
            },
            {
              // place(code-name) -> (id-name)
              // 出発場所
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 到着場所(ID)
              type: 'text',
              id: 'arrivalPlaceId',
              columnInfo: this.detailInfo.arrival_place_id,
            },
            {
              // place(code-name) -> (id-name)
              // 到着場所
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
            },
            {
              // 経由地場所(ID)
              type: 'text',
              id: 'placeId',
              columnInfo: this.detailInfo.place_id,
            },
            {
              // 経由地場所
              type: 'referenceCodeName',
              codeId: 'placeCode',
              nameId: 'placeName',
              codeColumnInfo: this.detailInfo.place_code,
              nameColumnInfo: this.detailInfo.place_name,
              btnColumnInfo: this.detailInfo.sub_modal_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                //...ボタン
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            //--------------
            // {
            //   // 到着日時
            //   type: 'dateTime',
            //   dateId: 'arrivalDate',
            //   timeId: 'arrivalTime',
            //   dateColumnInfo: this.detailInfo.arrival_date,
            //   timeColumnInfo: this.detailInfo.arrival_time,
            //   dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            // },
            // {
            //   // 出発日時
            //   type: 'dateTime',
            //   dateId: 'departureDate',
            //   timeId: 'departureTime',
            //   dateColumnInfo: this.detailInfo.departure_date,
            //   timeColumnInfo: this.detailInfo.departure_time,
            //   dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            // },
            //-----------
            {
              // 到着日時
              type: 'date',
              id: 'arrivalDate',
              columnInfo: this.detailInfo.arrival_date,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 到着日時
              type: 'time',
              id: 'arrivalTime',
              columnInfo: this.detailInfo.arrival_time,
            },
            {
              // 出発日時
              type: 'date',
              id: 'departureDate',
              columnInfo: this.detailInfo.departure_date,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出発日時
              type: 'time',
              id: 'departureTime',
              columnInfo: this.detailInfo.departure_time,
            },
            {},
          ],
        },
      ]
    },

    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      return info
    },
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    fieldsReceive() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS + '_recv'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    infoMessage() {
      return this.$store.getters['init/getMessage']('YZ00MG995I', '登録')
    },
    buttonItems() {
      return [
        // ボタングループ#1
        [],
      ]
    },
  },

  methods: {
    openSubModal,
    addRow,
    goAddRow,
    deleteRow,
    goDeleteRow,
    resetRow,
    goResetRow,
    clearDivisionVia,
    goResetCondition,
    goCancelRow,
    cancelRow,

    /**
     * 区間分割パターンマスタ参照から取得データ設定
     */
    afterCloseSetSectionDivision(selectedItem) {
      this.selectedPtnItem = selectedItem

      //再設定処理
      goResetRow(this.obj)
    },

    /**
     * 場所マスタ参照から取得データ設定と解除
     */
    afterCloseSetPlace(selectedItem) {
      this.obj.formData.placeId = selectedItem.place_id
      this.obj.formData.placeCode = selectedItem.place_code
      this.obj.formData.placeName = selectedItem.place_name
    },
    afterCloseUnsetPlace() {
      this.obj.formData.placeId = null
      this.obj.formData.placeCode = ''
      this.obj.formData.placeName = ''
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    /**
     * 削除ボタン
     */
    rowDeleteDisabledCheck() {
      var result = true //this.rowDeleteDisabled
      if (this.divTableItems.length > 1) {
        result = false
      }
      return result
    },
    /**
     * 解除ボタン
     */
    rowCancelDisabledCheck() {
      var result = true //this.rowCancelDisabled
      if (this.divTableItems.length > 1) {
        result = false
      }
      return result
    },

    /**
     * モーダルを閉じた後の処理です.
     */
    afterClose() {
      // 経由値情報クリア
      clearDivisionVia(this)
      this.$emit('after-close')
    },
  },
}
</script>
<style lang="scss" scoped>
.editRowBtn {
  margin-right: 3px;
  margin-top: 10px;
  margin-bottom: 3px;
}
.base-table {
  font-size: 0.8rem;
  th,
  td {
    padding: 5px;
    vertical-align: middle;
  }

  th {
    background-color: $primary;
    color: #eee;
    padding: 0 1rem;
    text-align: center;
    width: 130px;
  }
}
</style>
