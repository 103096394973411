export default class divisionViaInfo {
  placeId = null
  placeCode = null
  placeName = null
  arrivalDate = null
  arrivalTime = null
  departureDate = null
  departureTime = null

  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null
  arrivalPlaceId = null
  arrivalPlaceCode = null
  arrivalPlaceName = null
}
