import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
} from '@/common/Common.js'
import { formatYYYYMMDD, formatHHmmss } from '@/common/Common.js'
import {
  MSG_TYPE,
  COUNT_PARTITION_FLAG,
  PROCESS_KIND,
  PLANNING_STATUS,
} from '@/common/const.js'
import PlanningFlagService from './PlanningFlagService'
import _ from 'lodash'

export function init(obj) {
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
  // 経由値情報クリア
  clearDivisionVia(obj)

  //console.log('planningOrderId===>' + obj.planningOrderId)
  //console.log('entryKind===>' + obj.entryKind)

  if (obj.planningOrderId != null) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.findDetailSectionPartition(
      obj.planningOrderId,
      obj.entryKind
    )
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          //データ再表示
          initDataRefresh(obj, getDatas)

          //出発場所・到着場所
          obj.tableItems.forEach((element) => {
            // order info
            obj.formData.orderDetailId = element.order_detail_id
            obj.formData.orderBlockCode = element.order_block_code

            // departure
            obj.formData.departurePlaceId = element.departure_place_id
            obj.formData.departurePlaceCode = element.departure_place_code
            obj.formData.departurePlaceName = element.departure_place_name
            // arrival
            obj.formData.arrivalPlaceId = element.arrival_place_id
            obj.formData.arrivalPlaceCode = element.arrival_place_code
            obj.formData.arrivalPlaceName = element.arrival_place_name
            return // first exit
          })
        },
        (e) => {
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          // エラーモーダル表示
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 経由地の入力項目値情報クリア
 * @param {*} obj
 */
export function clearDivisionVia(obj) {
  obj.formData.placeId = null
  obj.formData.placeCode = null
  obj.formData.placeName = null
  obj.formData.arrivalDate = null
  obj.formData.arrivalTime = null
  obj.formData.departureDate = null
  obj.formData.departureTime = null
}

/**
 * データ再表示
 * @param {*} obj
 * @param {*} getDatas
 */
function initDataRefresh(obj, getDatas) {
  var datas = convertSnakeCace(getDatas)

  //上段：受注オーダー明細情報
  obj.tableItems = []
  obj.tableItems = [datas[0]]
  obj.packUpInfoList.tableItems = [datas[0]] //save oridata

  //下段：配車情報（分割データ）
  obj.divTableItems = []
  var divData = _.cloneDeep(datas)
  divData.splice(0, 1)
  obj.divTableItems = divData
  obj.packUpInfoList.divTableItems = divData //save oridata

  obj.oriData = _.cloneDeep(obj.packUpInfoList)
}

/**
 * 追加
 * @param {*} obj
 */
export async function goAddRow(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var errorMsg = checkAddAndDeleteData(obj, PROCESS_KIND.INSERT)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.row_add.label
    )
    obj.event = 'addRow'
    openSubModal(obj, 'sectionSplit-register-modal')
  }
}

// 経由地追加
export function addRow(obj) {
  obj.$bvModal.hide('sectionSplit-register-modal')

  // yyyy/mm/dd -> yyyymmdd
  // hh:mm:ss -> hhmmss

  let bOk = false
  let placeId = obj.formData.placeId
  let arrivalDate = formatYYYYMMDD(obj, obj.formData.arrivalDate)
  let arrivalTime = formatHHmmss(obj.formData.arrivalTime)
  let departureDate = formatYYYYMMDD(obj, obj.formData.departureDate)
  let departureTime = formatHHmmss(obj.formData.departureTime)
  if (placeId != null && placeId > 0) {
    bOk = true
  }

  let length = obj.divTableItems.length
  let index = 0
  let planningOrderId = 0
  let version = 0
  obj.divTableItems.forEach((divElement) => {
    index++
    // Last Record
    if (length == index) {
      planningOrderId = divElement.planning_order_id
      version = divElement.version
      //beginDate = divElement.departure_begin_date
      //endDate = divElement.departure_begin_date
    }
  })

  let registerList = {
    sectionPlaceId: placeId, //100007,
    sectionBeginDate: arrivalDate, //'20201230',
    sectionBeginTime: arrivalTime, //'193010',
    sectionEndDate: departureDate, //'20201230',
    sectionEndTime: departureTime, //'221510',
    planningOrderId: planningOrderId,
    version: version,
  }

  if (bOk == true && planningOrderId > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.addSectionPartition(
      planningOrderId, //obj.selectedOrderSectionSplitIds[1],
      registerList
    )
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          //データ再表示
          initDataRefresh(obj, getDatas)

          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.row_add.label
            )
          )

          // 正常登録後に経由地情報の初期化
          // obj.formData.placeId = ''
          // obj.formData.placeName = ''
          // obj.formData.departureTime = ''
          // obj.formData.arrivalTime = ''

          // 一覧再表示
          //obj.$bvModal.hide('order-section-split-modal')
          obj.$emit('after-close-set')
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 削除
 * @param {*} obj
 */
export function goDeleteRow(obj) {
  var errorMsg = checkAddAndDeleteData(obj, PROCESS_KIND.DELETE)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.row_delete.label
    )
    obj.event = 'deleteRow'
    openSubModal(obj, 'sectionSplit-register-modal')
  }
}

// 削除
export function deleteRow(obj) {
  obj.$bvModal.hide('sectionSplit-register-modal')

  let length = obj.divTableItems.length
  let index = 0
  let planningOrderId = 0
  let version = 0

  if (length <= 1) {
    // openMsgModal(
    //   obj,
    //   MSG_TYPE.E,
    //   obj.$store.getters['init/getMessage']('YZ00MG916E')
    // )
    return
  }

  obj.divTableItems.forEach((divElement) => {
    index++
    // Last Record
    if (length == index) {
      planningOrderId = divElement.planning_order_id
      version = divElement.version
    }
  })

  let registerList = {
    planningOrderId: planningOrderId,
    version: version,
  }

  if (planningOrderId > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.delSectionPartition(planningOrderId, registerList)
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          //データ再表示
          initDataRefresh(obj, getDatas)

          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.row_delete.label
            )
          )
          // 一覧再表示
          //obj.$bvModal.hide('order-section-split-modal')
          obj.$emit('after-close-set')
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 「再設定」ボタン
 * (再設定の出発ID,到着ID設定)
 * @param {*} obj
 */
export function goResetCondition(obj) {
  var errorMsg = checkResetData(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    let paraDeparturePlaceId = null
    let paraArrivalPlaceId = null
    if (obj.formData.departurePlaceId != null) {
      paraDeparturePlaceId = obj.formData.departurePlaceId + '' //ID (String)
    }
    if (obj.formData.arrivalPlaceId != null) {
      paraArrivalPlaceId = obj.formData.arrivalPlaceId + '' //ID (String)
    }
    obj.sectionDivisionModalSearchCondition = {
      departurePlaceId: paraDeparturePlaceId,
      arrivalPlaceId: paraArrivalPlaceId,
    }
    openSubModal(obj, 'section-division-modal-three')
  }
}

/**
 * 「再設定」->「区間分割パターンマスタ」ボタン
 * @param {*} obj
 */
export function goResetRow(obj) {
  obj.$bvModal.hide('section-division-modal')

  //%s処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.row_reset.label
  )
  obj.event = 'resetRow'
  openSubModal(obj, 'sectionSplit-register-modal')
}

// 再設定
export function resetRow(obj, selectedItem) {
  obj.$bvModal.hide('sectionSplit-register-modal')

  let itemLength = selectedItem.length
  if (itemLength == 0) return

  let length = obj.divTableItems.length
  if (length == 0) return

  let planningOrderId = 0
  let version = 0
  obj.divTableItems.forEach((divElement) => {
    planningOrderId = divElement.planning_order_id
    version = divElement.version
    return
  })

  let sectionDivisionId = selectedItem[0].section_division_id

  let registerList = {
    planningOrderId: planningOrderId,
    version: version,
    sectionDivisionId: sectionDivisionId,
  }

  if (planningOrderId > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.resetSectionPartition(planningOrderId, registerList)
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          //データ再表示
          initDataRefresh(obj, getDatas)

          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.row_reset.label
            )
          )
          // 一覧再表示
          //obj.$bvModal.hide('order-section-split-modal')
          obj.$emit('after-close-set')
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 解除
 * @param {*} obj
 */
export function goCancelRow(obj) {
  var errorMsg = checkCancelData(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.row_cancel.label
    )
    obj.event = 'cancelRow'
    openSubModal(obj, 'sectionSplit-register-modal')
  }
}

// 解除
export function cancelRow(obj) {
  obj.$bvModal.hide('sectionSplit-register-modal')

  let length = obj.divTableItems.length
  if (length == 0) return

  let planningOrderId = 0
  let version = 0
  obj.divTableItems.forEach((divElement) => {
    planningOrderId = divElement.planning_order_id
    version = divElement.version
    return
  })

  let registerList = {
    planningOrderId: planningOrderId,
    version: version,
  }

  if (planningOrderId > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.cancelSectionPartition(planningOrderId, registerList)
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data)
          //データ再表示
          initDataRefresh(obj, getDatas)

          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.resultControlInfo.row_cancel.label
            )
          )
          // 一覧再表示
          //obj.$bvModal.hide('order-section-split-modal')
          obj.$emit('after-close-set')
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

/**
 * 「再設定」ボタン押下後、整合性チェック
 * @param {*} obj
 */
function checkResetData(obj) {
  var errorMsg = null
  let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']
  //console.log('authentication/getBlockCode:', loginUserBlockCode)

  if (obj != null) {
    // //x.選択行すべてのブロックコードが等しいことをチェック
    // // 受注明細のブロック≠ログインブロック（配車オーダーのブロック）
    // obj.packUpInfoList.divTableItems.forEach((element) => {
    //   console.log('element.block_code:', element.block_code)
    //   console.log('element.order_block_code:', element.order_block_code)
    //   if (element.block_code != element.order_block_code) {
    //     errorMsg = obj.$store.getters['init/getMessage'](
    //       'EB01S001_MG003E',
    //       '異なるブロックコード'
    //     )
    //     return errorMsg
    //   }
    // })

    //[No.2469]
    //再設定が実施可能なのは、以下の条件の時。
    //・対象の配車オーダーに紐づく受注のブロック＝ログイン中のブロック
    // →上段の1件（受注関連）でチェックを行う。
    obj.packUpInfoList.tableItems.forEach((element) => {
      // console.log('tableItems_element.block_code:', element.block_code)
      // console.log(
      //   'tableItems_element.order_block_code:',
      //   element.order_block_code
      // )
      if (loginUserBlockCode != element.order_block_code) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '異なるブロックコード'
        )
        return errorMsg
      }
    })
  }

  return errorMsg
}

/**
 * 「解除」ボタン押下後、整合性チェック
 * @param {*} obj
 */
function checkCancelData(obj) {
  var errorMsg = null
  let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']
  //console.log('authentication/getBlockCode:', loginUserBlockCode)

  if (obj != null) {
    // //x.選択行すべてのブロックコードが等しいことをチェック
    // // 受注明細のブロック≠ログインブロック（配車オーダーのブロック）
    // obj.packUpInfoList.divTableItems.forEach((element) => {
    //   console.log('element.block_code:', element.block_code)
    //   console.log('element.order_block_code:', element.order_block_code)
    //   if (element.block_code != element.order_block_code) {
    //     errorMsg = obj.$store.getters['init/getMessage'](
    //       'EB01S001_MG003E',
    //       '異なるブロックコード'
    //     )
    //     return errorMsg
    //   }
    // })

    //[No.2469]
    //解除が実施可能なのは、以下の条件の時。
    //・対象の配車オーダーに紐づく受注のブロック＝ログイン中のブロック
    // →上段の1件（受注関連）でチェックを行う。
    obj.packUpInfoList.tableItems.forEach((element) => {
      // console.log('tableItems_element.block_code:', element.block_code)
      // console.log(
      //   'tableItems_element.order_block_code:',
      //   element.order_block_code
      // )
      if (loginUserBlockCode != element.order_block_code) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '異なるブロックコード'
        )
        return errorMsg
      }
    })
  }

  return errorMsg
}

/**
 * 「経由値追加」「削除」ボタン押下後、整合性チェック
 * @param {*} obj
 */
function checkAddAndDeleteData(obj, processKind) {
  var errorMsg = null
  let loginUserBlockCode = obj.$store.getters['authentication/getBlockCode']

  if (obj != null) {
    let length = obj.divTableItems.length
    let index = 0
    if (length == 0) {
      return null // チェックなし
    }

    // 削除処理時、元配車オーダーIDへ影響あり。
    let orgin_planning_order_id = null
    if (PROCESS_KIND.DELETE == processKind) {
      obj.packUpInfoList.divTableItems.forEach((element) => {
        orgin_planning_order_id = element.orgin_planning_order_id
      })
    }

    // 削除配車オーダーIDと更新配車オーダーIDのいずれかの配車オーダー情報の
    // 数量分割フラグが「1：分割あり」の場合はエラー
    obj.packUpInfoList.divTableItems.forEach((element) => {
      index++
      // 追加：Last情報のみチェック (1record)
      // 削除：LastとBefore Last情報チェック (2records)
      var checkrun = false
      if (PROCESS_KIND.INSERT == processKind) {
        if (length == index) {
          checkrun = true
        }
      }
      if (PROCESS_KIND.DELETE == processKind) {
        if (length == index || length - 1 == index) {
          checkrun = true
        }
        // 同じ[元配車オーダーID]
        if (
          orgin_planning_order_id != null &&
          orgin_planning_order_id == element.orgin_planning_order_id
        ) {
          checkrun = true
        }
      }
      //追加と削除ボタン
      // Last Record & 1Before Last Record
      if (checkrun == true) {
        //----------------->>
        //console.log('element.count_partition_flag:', element.count_partition_flag)
        //console.log('element.block_code:', element.block_code)
        //-----------------<<
        //・対象区間に配車オーダーのステータスが未割付かチェック
        if (
          element.planning_status == PLANNING_STATUS.ASSIGNED ||
          element.planning_status == PLANNING_STATUS.COMPLETE
        ) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG007E',
            '割付済み'
          )
          return errorMsg
        }
        //・対象区間にガント排他中のレコードを含んでいるかチェック
        if (element.user_id != null) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG007E',
            '配車中のオーダ'
          )
          return errorMsg
        }

        //・対象区間の配車オーダーが数量分割されていない
        if (element.count_partition_flag == COUNT_PARTITION_FLAG.YES) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG007E',
            '数量分割オーダ'
          )
          return errorMsg
        }
        //・対象区間の配車オーダーのブロック＝ログイン中のブロック
        if (loginUserBlockCode != element.block_code) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG003E',
            '異なるブロックコード'
          )
          return errorMsg
        }
      }
    })
  }

  return errorMsg
}
